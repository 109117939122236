import { useEffect, useState } from 'react';
import { Link , useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getData } from 'modules/auth';

import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { ArrowUpIcon } from 'modules/v2/common/components/SvgIcon';
import { getRoutePlansAndBillings } from 'modules/v2/routes/navigation';
import { DashboardTemplate } from 'modules/v2/templates';
import { PlansProvider } from '../PlansAndBilling/context';
import ChoosePlan from './ChoosePlan';
import Payment from './Payment';

const Plans = () => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState('choose-plan');
  const [upgradePlan, setUpgradePlan] = useState('');
  const [upgradePlanCycle, setUpgradePlanCycle] = useState('');
  const [upgradeFlow, setUpgradeFlow] = useState<'payment' | 'contract'>('payment');
  const [isBookMember, setIsBookMember] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const { userData } = getData();
    if (
      userData?.hasBookAccess &&
      (!userData?.hideBooks || (userData?.subscriptions?.length > 0 && userData?.subscriptions.includes('AFY')))
    ) {
      setIsBookMember(true);
    }
  }, []);

  const subHeader = (
    <div className="w-full flex gap-2 mb-[22px] h-[20px]">
      <Link to={getRoutePlansAndBillings()} className="text-neutral-700">
        Plan and billing
      </Link>
      <div className="text-neutral-700 rotate-90">
        <ArrowUpIcon fill="#757575" />
      </div>
      <div
        className={classNames(
          activeItem === 'choose-plan' ? 'border-b-[1px] border-primary-500 font-bold' : 'border-0',
          'text-sm whitespace-nowrap cursor-pointer',
        )}
        onClick={() => setActiveItem('choose-plan')}
      >
        Choose plan
      </div>
      <div className="text-neutral-700 rotate-90">
        <ArrowUpIcon />
      </div>
      <div
        className={classNames(
          activeItem === 'payment'
            ? 'border-b-[1px] border-primary-500 font-bold'
            : 'border-0 text-neutral-400',
          'pb-4 text-sm whitespace-nowrap',
        )}
      >
        Payment
      </div>
    </div>
  );

  return (
    <DashboardTemplate hasSidebar subHeader={isBookMember && subHeader}>

      { !isBookMember && (
        <div>
          <div className="w-1/12 flex gap-1 items-center cursor-pointer" onClick={goBack}>
            <ArrowLeft stroke='#3C3C3C'/>
            <span className='font-medium text-sm'>Back</span>
          </div>
          <div className='font-extrabold text-lg'>
          Choose your Plan
          </div>
        </div>
      )}
      <PlansProvider>
        {activeItem === 'payment' ? (
          <Payment
            setUpgradeFlow={setUpgradeFlow}
            upgradeFlow={upgradeFlow}
            plan={upgradePlan}
            cycle={upgradePlanCycle}
          />
        ) : (
          <ChoosePlan
            setUpgradeFlow={setUpgradeFlow}
            setStep={setActiveItem}
            setPlan={setUpgradePlan}
            setCycle={setUpgradePlanCycle}
            isBookMember={isBookMember}
          />
        )}
      </PlansProvider>
    </DashboardTemplate>
  );
};

export default Plans;
