export const UPDATE_ISBOOKS = 'UPDATE_ISBOOKS';
export const UPDATE_ISRM = 'UPDATE_ISRM';
export const UPDATE_ISGUIDES = 'UPDATE_ISGUIDES';

export const updateIsBooks = (IsBooks) => ({
  type: UPDATE_ISBOOKS,
  payload: IsBooks,
});

export const updateIsRm = (IsRm) => ({
  type: UPDATE_ISRM,
  payload: IsRm,
});

export const updateIsGuides = (isGuides) => ({
  type: UPDATE_ISGUIDES,
  payload: isGuides,
});
