import { useState } from 'react';
import { DashboardTemplate } from 'modules/v2/templates';
import { useQuery } from 'react-query';
import { Modal as FlowbiteModal } from 'flowbite-react';
import { getLastMagazine } from 'modules/api';

import SubscribeMagazine from './components/SubscribeMagazine';
import ModalSubscribeMagazine from './components/ModalSubscribeMagazine';

const ReferralMarketingGuides = () => {
  const [openModal, setOpenModal] = useState(true);

  const { data: lastMagazineData, isLoading: isAppConfigLoading } = useQuery(
    'getLastMagazine',
    getLastMagazine,
  );

  const { month, year, frontCoverDesign = [] } = lastMagazineData || {};
  const coversUrl = frontCoverDesign.map((design) => design.displayImage?.data?.attributes?.url);

  return(
    <DashboardTemplate title="Magazines">
      <div className="w-full">
        <SubscribeMagazine />

        <div className='bg-white py-5 px-6'>
          <div className='text-base font-semibold'>
            {month} {year} Magazine
          </div>
        </div>
        <hr />
        <div className='bg-white py-5 px-6'>
          <div className="pb-0 flex flex-wrap gap-3.5 mb-1 mt-2">
            <div className="flex flex-wrap gap-3.5">
              <div className="flex flex-row gap-2 flex-wrap max-md:flex overflow:hidden">
                {coversUrl.map((url) => (
                  <div className="overflow-hidden rounded-md" key={url}>
                    <img
                      className="transition-all duration-300 hover:scale-110 rounded-md w-[252px] h-[322px]"
                      src={url}
                      alt="magazine"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FlowbiteModal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        size="4xl"
        theme={{
          body: {
            base: 'h-full',
          },
          sizes: {
            '6xl': 'max-w-6xl',
          },
        }}
      >
        <FlowbiteModal.Body>
          <ModalSubscribeMagazine setOpenModal={setOpenModal} />
        </FlowbiteModal.Body>
      </FlowbiteModal>
    </DashboardTemplate>
  );
};
export default ReferralMarketingGuides;