import { DashboardTemplate } from 'modules/v2/templates';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { RestartIcon } from 'modules/v2/common/components/SvgIcon';
import { Tooltip } from 'flowbite-react';
import { GuidePreview } from './components/GuidePreview';
import guideMockImg from './mocks/guideMock.png';

const GuideCatalog = () => {

  return (
    <DashboardTemplate title="Guide Catalog">
      <div className="w-full flex justify-between items-center bg-neutral-0 py-[15px] px-6 rounded-t-lg border-b border-neutral-200 shadow-box max-[650px]:flex-col">
        <div className="w-[308px]">
          <TextInput
            placeholder="Search title or category"
            sizing="md"
            errorMessage="Invalid input"
            className=""
            color="gray"
          />
        </div>

        <Tooltip style="dark" content="Reset filters">
          <Button className="w-[38px] h-[38px]" type="muted">
            <RestartIcon />
          </Button>
        </Tooltip>
      </div>
      <div className="bg-neutral-0 py-[15px] px-6 rounded-b-lg shadow-box">
        <div className="grid gap-5 grid-cols-[repeat(auto-fill,252px)] w-full justify-center py-[30px] px-6">
          {Array.from({ length: 20 }).map(() => (
            <GuidePreview guideImage={guideMockImg} />
          ))}
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default GuideCatalog;

