import { useState, useRef, useEffect } from 'react';
import { ArrowDown } from 'modules/v2/common/components/SvgIcon';

const Dropdown = ({ items, setSelectedQtyOption, setGuideQuantity, selectedQtyOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const setOnClick = (value) => {
    const result = items.find(item => item.label === value.target.innerHTML);
    setSelectedQtyOption(result);
    setGuideQuantity(result?.qty);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (

    <div className="relative" ref={ref}>
      <button onClick={() => setIsOpen(true)} id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
        className="border border-neutral-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-md text-sm px-8 py-3.5 text-center inline-flex items-center" type="button">
        {selectedQtyOption?.label}
        <ArrowDown />
      </button>
      {isOpen && (
        <div id="dropdown" className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {items.map((item) => (
              <li
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer" 
                onClick={(value) => setOnClick(value)}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
