import { useState } from 'react';
import { useQuery } from 'react-query';
import { getActiveSubscriptions } from 'modules/api';
import { BoltIcon, CheckIconNew } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { Divider } from 'antd';
import {
  BASIC_PLANS,
  formatCurrency,
  INTERVAL_MONTHLY,
  INTERVAL_YEARLY,
  IntervalType,
  PlansType,
  PLUS_PLANS,
  PLAN_ALIAS_DIGITAL,
  PLAN_ALIAS_PREMIUM,
  PLAN_ALIAS_PLATINUM,
  PRINTED_BOOKS_FEATURE,
  SOCIAL_MEDIA_FEATURE,
} from '../utils';
import { SwitchButton } from '../../../common/AtomicDesign/atoms';
import { getCurrent } from '../../../../api/afyPlans/plans';
import { PlansSkeleton } from './plansSkeleton';

const ChoosePlan = ({ setStep, setPlan, setCycle, setUpgradeFlow, isBookMember }) => {
  const { data: subscription = {} } = useQuery(['getSubscription'], getActiveSubscriptions);

  const plansComponentData = {
    planHandle: subscription?.product?.product_family?.handle,
    componentHandle: subscription?.component?.component_handle,
  };

  const [possiblePlanTypes, setPossiblePlanTypes] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState<PlansType>(BASIC_PLANS);
  const [selectedInterval, setInterval] = useState<IntervalType>(INTERVAL_MONTHLY);
  const [plansToShow, setPlansToShow] = useState([]);
  const [basicPlans, setBasicPlans] = useState([]);
  const [plusPlans, setPlusPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);

  const selectPlanType = (type) => {
    if (type === 'Plus') {
      setSelectedPlanType(PLUS_PLANS);
      setPlansToShow(plusPlans);
      setUpgradeFlow('contract');
    } else {
      setSelectedPlanType(BASIC_PLANS);
      setPlansToShow(basicPlans);
      setUpgradeFlow('payment');
    }
  };

  const getPlanBillingOptionFromCurrentInterval = (plan) => {
    return plan.billingOptions.filter((item) => item.interval === selectedInterval)[0];
  };

  const getPlanValueFromCurrentInterval = (plan) => {
    const value = getPlanBillingOptionFromCurrentInterval(plan).value / 100;

    if (selectedInterval === 'annual') {
      return value / 12;
    }

    return value;
  };

  const { isLoading: getPlansIsLoading } = useQuery(
    ['getAvailablePlans', plansComponentData],
    () => getCurrent(plansComponentData),
    {
      onSuccess: (availablePlans) => {
        setCurrentPlan({
          ...availablePlans?.plan,
          componentHandle: subscription?.component?.component_handle,
        });

        const planTypes = new Set(
          availablePlans?.product?.upgradableTo.map((item: any) => item?.planAlias),
        );
        planTypes.add(availablePlans?.product?.planAlias);

        setPossiblePlanTypes([...Array.from(planTypes)]);

        if (planTypes.size === 1) {
          selectPlanType(planTypes.values().next().value);
        } else {
          selectPlanType(BASIC_PLANS);
        }

        const bPlans =
          availablePlans?.product.upgradableTo.filter(
            (item: any) => item.planAlias === BASIC_PLANS,
          ) || [];
        const pPlans =
          availablePlans?.product.upgradableTo.filter(
            (item: any) => item.planAlias === PLUS_PLANS,
          ) || [];

        const currentIsYearly = subscription?.component?.component_handle.includes(INTERVAL_YEARLY);

        // removing monthly plans if current plan is yearly to avoid downgrading.
        if (currentIsYearly) {
          availablePlans.product.billingOptions = availablePlans?.product?.billingOptions.map(
            (item: any) => {
              if (item.interval === INTERVAL_MONTHLY) {
                item.componentHandle = 'unavailable';
              }
              return item;
            },
          );
        }

        const nProd = {
          ...availablePlans?.product,
        };

        if (availablePlans?.product?.planAlias === BASIC_PLANS) {
          bPlans.unshift(nProd);
        }

        if (availablePlans?.product?.planAlias === PLUS_PLANS) {
          pPlans.unshift(nProd);
        }

        // Sorting plans by value
        bPlans.sort((a: any, b: any) => a.billingOptions[0].value - b.billingOptions[0].value);
        pPlans.sort((a: any, b: any) => a.billingOptions[0].value - b.billingOptions[0].value);

        setBasicPlans(bPlans);
        setPlusPlans(pPlans);
      },
    },
  );

  const handleUpgradePlan = (plan: any) => {
    const cycle = getPlanBillingOptionFromCurrentInterval(plan).interval;
    setCycle(cycle);
    setPlan(plan);
    setStep('payment');
  };

  const getDefaultList = () => {
    let defaultList: any;
    if (plansToShow.length === 0) {
      defaultList = selectedPlanType === 'Basic' ? basicPlans : plusPlans;
    } else {
      defaultList = plansToShow;
    }

    return defaultList;
  };

  const showOnlyAnnualSelected = (plan: any) => {
    const billingOption = getPlanBillingOptionFromCurrentInterval(plan);
    return billingOption.componentHandle !== 'unavailable';
  };

  const upgradePlanButtons = (plan: any) => {
    const billingOption = getPlanBillingOptionFromCurrentInterval(plan);

    if (billingOption.componentHandle === currentPlan.componentHandle && !guideMember) {
      return (
        <Button color="outilined" full className="border border-neutral-200 cursor-default">
          Current plan
        </Button>
      );
    }

    return (
      <Button onClick={() => handleUpgradePlan(plan)} full>
        { !isBookMember ? (<div className="flex gap-2 items-center">
          <span>Choose</span>
        </div>) : (
          <div className="flex gap-2 items-center">
            <BoltIcon />
            <span>Upgrade plan</span>
          </div>
        )}
      </Button>
    );
  };

  const firstFeatures = {
    licensed_books: {
      title: 'Licensed books',
      description:
        'Access a library of real estate books templates featuring specialized categories, offering an extensive range of topics.',
    },
    digital_books: {
      title: 'Digital books',
      description:
        'Share and conduct presentations with your digital book with anyone in the world.',
    },
    lead_capture_websites: {
      title: 'Lead capture websites',
      description:
        'Attract and promote your business and collect leads. Get instant notifications for new leads, enabling you to follow up promptly and secure the deal.',
    },
    printed_books: {
      title: 'Printed books',
      description:
        'Get printed book credits to buy customized physical copies of your books with your professional photo and branding.',
    },
  };

  const secondFeatures = {
    marketing_templates: {
      title: 'Marketing templates',
      description:
        'Save time and effort while maximizing your reach with our ready-to-use and customizable marketing templates and scripts to boost lead generation.',
    },
    training_site_access: {
      title: 'Training site access',
      description:
        'Access to 100+ articles, videos, and templates designed to help you with your sales and maximize the potential of your Authorify membership,',
    },
    coaching: {
      title: 'Coaching',
      description:
        'Benefit from live coaching sessions with industry experts who will guide you through personalized strategies and provide valuable insights.',
    },
    retargeting: {
      title: 'Retargeting',
      description:
        'Reach your ideal audience with our retargeting campaigns strategically place you in front of individuals who have previously shown interest in your ads thus increase the chances of converting them into loyal clients.',
    },
    google_my_business: {
      title: 'Google my business',
      description:
        'Get a Google Business Profile with Authorify. Take control of your online visibility, generate more leads, and cultivate a positive reputation that sets you apart in your industry.',
    },
    social_media_training_site: {
      title: 'Social media training site',
      description:
        'Access to 100+ articles, videos and templates designed to build your social brand. Each month we will be adding a new set of templates and scripts to help you fill up your social media calendar.',
    },
  };

  const displayFeatureNumber = (feature) => {
    const { name, amount, prefix, suffix, available } = feature || {
      name: '',
      amount: 0,
      prefix: '',
      suffix: '',
      available: false,
    };
    return available ? (
      <span className="flex gap-2">
        {amount !== 0 ? <CheckIconNew width={20} height={20} fill="#38AB86" /> : null}
        {prefix}
        {amount !== 0 ? <span className="font-medium">{amount}</span> : ''}
        {name} {suffix}
      </span>
    ) : (
      ''
    );
  };

  const displayFeatureHeader = (item) => {
    if (item.alias.includes(PLAN_ALIAS_DIGITAL)) {
      return (
        <div>
          <p className="font-semibold mb-[14px]">Key features</p>
          {Object.keys(secondFeatures).reduce((accumulator, feature) => {
            const feat = item.features.find((f) => f.handle === feature);
            if (feat) {
              accumulator.push(displayFeatureNumber(feat));
            }
            return accumulator;
          }, [])}
        </div>
      );
    }
    if (item.alias.includes(PLAN_ALIAS_PREMIUM)) {
      const feat = item.features.find((f) => f.handle === PRINTED_BOOKS_FEATURE);
      return (
        <div>
          <p className="font-semibold mb-[14px]">All in Digital, plus:</p>
          {feat && displayFeatureNumber(feat)}
        </div>
      );
    }
    if (item.alias.includes(PLAN_ALIAS_PLATINUM)) {
      const feat = item.features.find((f) => f.handle === SOCIAL_MEDIA_FEATURE);
      return (
        <div>
          <p className="font-semibold mb-[14px]">All in Premium, plus:</p>
          {feat && displayFeatureNumber(feat)}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-[275px] p-6 flex flex-col items-center relative">
          <div className="bg-toggleButtonBg w-full flex rounded-lg mb-3.5">
            <Button
              disabled={!possiblePlanTypes.includes(BASIC_PLANS) || getPlansIsLoading}
              onClick={() => selectPlanType(BASIC_PLANS)}
              color={selectedPlanType === BASIC_PLANS ? 'outlined' : 'muted'}
              className={`rounded-lg focus:border-neutral-200 hover:border-neutral-200 ${selectedPlanType ===
                BASIC_PLANS && 'bg-white hover:bg-white'}`}
              size="sm"
              full
            >
              Basic
            </Button>
            <Button
              disabled={!possiblePlanTypes.includes(PLUS_PLANS) || getPlansIsLoading}
              onClick={() => selectPlanType(PLUS_PLANS)}
              type={selectedPlanType === PLUS_PLANS ? 'outlined' : 'muted'}
              className={`rounded-lg focus:border-neutral-200 hover:border-neutral-200 ${selectedPlanType ===
                PLUS_PLANS && 'bg-white hover:bg-white'}`}
              size="sm"
              full
            >
              Plus
            </Button>
          </div>
          <div className="top-[37px] left-[260px] w-[220px] absolute bg-primary-50 text-primary-700 text-xs font-medium me-2 mb-2 px-2 py-1 rounded-full flex gap-2 max-[700px]:left-[30px] max-[700px]:top-[0px]">
            <span>Get more benefits with Plus plans! 🎉</span>
          </div>
          <div className="flex items-center pt-1 pb-4">
            <SwitchButton
              disabled={getPlansIsLoading}
              isUpgradePlan
              checked={selectedInterval === INTERVAL_YEARLY}
              setChecked={
                getPlansIsLoading
                  ? null
                  : (e) => (e ? setInterval(INTERVAL_YEARLY) : setInterval(INTERVAL_MONTHLY))
              }
              showCloseIcon={false}
              className="mr-3"
            />
            <div className="font-medium mr-1">Billed yearly</div>
            <span className="font-font-thin text-neutral-500">(Save 16%)</span>
          </div>
        </div>
      </div>
      {getPlansIsLoading && <PlansSkeleton />}
      <div>
        <div className="flex justify-center items-center w-full h-full gap-1 max-[1250px]:flex-col">
          {getDefaultList().map((item: any) => {
            return showOnlyAnnualSelected(item) ? (
              <div className="p-6 bg-white rounded-lg shadow-box h-[560px] max-w-[367px] min-w-[305px] w-full">
                <div className="flex mb-5">
                  <div className="font-semibold text-base mr-2">{item.alias}</div>
                  {selectedInterval === INTERVAL_YEARLY && (
                    <span className="bg-orange-50 text-orange-600 text-xs font-medium px-2 py-1 rounded-full w-fit flex gap-2">
                      -16%
                    </span>
                  )}
                </div>
                <div className="flex mb-2">
                  <span className="font-semibold text-2xl">
                    {formatCurrency(getPlanValueFromCurrentInterval(item), false)}
                  </span>
                  <span className="text-neutral-600 text-base">/ mo</span>
                </div>
                <div className="mb-3 h-5 text-neutral-600 font-bold text-sm">
                  {selectedInterval === INTERVAL_YEARLY ? (
                    <span>
                      Billed{' '}
                      {formatCurrency(
                        getPlanBillingOptionFromCurrentInterval(item).value / 100,
                        false,
                      )}{' '}
                      yearly
                    </span>
                  ) : (
                    'Billed monthly'
                  )}
                </div>
                {upgradePlanButtons(item)}
                <div className="min-h-[115px]">
                  <Divider />
                  {Object.keys(firstFeatures).reduce((accumulator, feature) => {
                    const feat = item.features.find((f) => f.handle === feature);
                    if (feat) {
                      accumulator.push(displayFeatureNumber(feat));
                    }
                    return accumulator;
                  }, [])}
                </div>
                <div className="min-h-[115px]">
                  <Divider />
                  {displayFeatureHeader(item)}
                </div>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;
