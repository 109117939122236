export const CartIcon = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
  >
    <path
      d="M1.73672 2.29263C1.85121 1.96699 2.208 1.79582 2.53364 1.91031L2.78481 1.99861C3.29858 2.17922 3.73494 2.33262 4.07835 2.50112C4.44554 2.68129 4.76041 2.90323 4.99723 3.24978C5.23221 3.59365 5.32923 3.97092 5.374 4.38457C5.39405 4.56973 5.40462 4.77468 5.4102 4.99996H14.2754C15.6795 4.99996 16.9435 4.99996 17.3134 5.48086C17.6834 5.96176 17.5387 6.68637 17.2495 8.13559L16.833 10.1562C16.5704 11.4303 16.4391 12.0673 15.9794 12.442C15.5196 12.8166 14.8692 12.8166 13.5683 12.8166H9.14924C6.82515 12.8166 5.66311 12.8166 4.94111 12.0551C4.21911 11.2936 4.16641 10.4846 4.16641 8.03329L4.16641 5.86522C4.16641 5.2486 4.16556 4.83592 4.13126 4.51908C4.09849 4.21629 4.0404 4.06511 3.96519 3.95504C3.8918 3.84765 3.78041 3.74729 3.52772 3.62331C3.25869 3.4913 2.89306 3.36167 2.33672 3.16608L2.11904 3.08955C1.79341 2.97506 1.62223 2.61827 1.73672 2.29263Z"
      fill="#FAFAFA"
    />
    <path
      d="M6.24992 15C6.94027 15 7.49992 15.5596 7.49992 16.25C7.49992 16.9403 6.94027 17.5 6.24992 17.5C5.55956 17.5 4.99992 16.9403 4.99992 16.25C4.99992 15.5596 5.55956 15 6.24992 15Z"
      fill="#FAFAFA"
    />
    <path
      d="M13.7499 15C14.4403 15 14.9999 15.5597 14.9999 16.25C14.9999 16.9404 14.4403 17.5 13.7499 17.5C13.0596 17.5 12.4999 16.9404 12.4999 16.25C12.4999 15.5597 13.0596 15 13.7499 15Z"
      fill="#FAFAFA"
    />
  </svg>
);
