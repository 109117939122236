import { useMutation } from 'react-query';
import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import env from 'environments';
import { setCustomerPassword, fetchSession } from 'modules/api';
import { notification } from 'modules/common/utils';

import { getItem } from 'modules/dashboard/utils/legacy';

export const useWaitUpsellPayment = ({ onSuccess }) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const waitPayment = () => setIsWaiting(true);

  useEffect(() => {
    if (!isWaiting) return;

    const socket = io(env.SOCKET_IO);
    const subscription = JSON.parse(getItem('customerAndSubscriptionId'));
    const { customer, id } = subscription || {};
    const paymentEvent = `payment_${customer?.email}_${id}`;
    const upsellEvent = `upsell_${customer?.email}_${id}`;

    socket.on(paymentEvent, (data) => {
      notification.warning({
        title: 'Payment',
        description: 'Payment is processing... Please keep waiting!',
      });
    });

    socket.on(upsellEvent, (data) => {
      setIsWaiting(false);
      onSuccess(data);
    });

    return () => {
      socket.disconnect();
    };
  }, [isWaiting]);

  return { waitPayment };
};

export const useSetCustomerPassword = (options) => {
  return useMutation(setCustomerPassword, options);
};
