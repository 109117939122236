import { getInstance } from 'modules/api/digitalServices/instance';

const instance = getInstance();

export const getGlobalProfileMockData = () => {
  return {
    data: {
      firstName: 'test first',
      lastName: 'last test',
      email: 'test@gmail.com',
      phone: '123123123',
      website: 'test.com',
      avatar: '',
      brokerLogo: {
        status: false,
        value: null,
        companyName: '',
      },
      address: {
        address1: 'addres1 test',
        address2: 'complement test',
        city: 'city test',
        state: 'state test',
        zip: '123123',
        country: 'US',
      },
    },
  };
};

export const updateGlobalProfileMockData = ({ payload }) => {
  return {
    data: {
      firstName: 'test first',
      lastName: 'last test',
      email: 'test@gmail.com',
      phone: '123123123',
      website: 'test.com',
      avatar: '',
      brokerLogo: {
        status: false,
        value: null,
        companyName: '',
      },
      address: {
        address1: 'addres1 test',
        address2: 'complement test',
        city: 'city test',
        state: 'state test',
        zip: '123123',
        country: 'US',
      },
    },
  };
};
