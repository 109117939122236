import { GET_GLOBAL_PROFILE, UPDATE_GLOBAL_PROFILE } from 'modules/dashboard/store/constants';

export const getGlobalProfile = () => ({
  type: GET_GLOBAL_PROFILE,
});

export const updateGlobalProfile = (payload) => ({
  type: UPDATE_GLOBAL_PROFILE,
  payload,
});
