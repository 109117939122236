import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';

import { publishGuide } from 'modules/api';
import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { CheckIconGuides, PaintPaletteIcon, CloseCircleIcon, InfographicIconFilled, GuideIconFilled } from 'modules/v2/common/components/SvgIcon';
import { InfoIcon } from 'modules/v2/common/components/SvgIcons';
import Spinner from 'modules/v2/common/components/SvgIcon/Spinner';
import { getRouteGuideCatalog } from 'modules/v2/routes/navigation';
import { colors } from '../utils';

const EditGuide = ({ history, setIsPublishedGuide }) => {

  const [isInfographicView, setIsInfographicView] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<string>('blue');
  const [displayedImage, setDisplayedImage] = useState<string>('');
  const [hideOptions, setHideOptions] = useState<boolean>(false);

  const handleViewSwitch = (view: 'guide' | 'infographic') => {
    setIsInfographicView(view === 'infographic');
  };


  const { mutate: publishGuideMutation, isLoading: isPublishing } = useMutation(
    publishGuide,
    {
      onSuccess: () => {
        setIsPublishedGuide(true);
      },
    }
  );

  const handlePublish = () => {
    publishGuideMutation({ guideId: 'some-guide-id' });

    console.log('Publish');
  };

  const handleGoBack = () => {
    history.push(getRouteGuideCatalog());
  };

  const getColorFromUrl = (url: string): string => {
    const urlParts = url.split('-');
    const lastPart = urlParts[urlParts.length - 1];
    return lastPart.split('.')[0];
  };

  const mockData = {
    title: 'A guide to selling your home',
    image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
    html: '<p>Some HTML content</p>',
    availableColors: ['blue', 'green', 'red', 'gold', 'purple', 'white'],
  };

  useEffect(() => {
    if (mockData?.image) {
      setSelectedColor(getColorFromUrl(mockData.image));
      setDisplayedImage(mockData.image);
    }
  }, []);

  useEffect(() => {
    let newImage = mockData.image.replace(getColorFromUrl(mockData.image), selectedColor);
    newImage = isInfographicView ? newImage.replace('seller-guide', 'seller-infographic') : newImage;
    setDisplayedImage(newImage);
  }, [selectedColor, isInfographicView]);

  const hasAvailableColors = mockData.availableColors.length > 0;

  const cards = () => {
    if (!hasAvailableColors) {
      return <img src={mockData?.image} alt={mockData.title} />;
    }

    return (
      <>
        {mockData.availableColors?.map((color: string) => {
          let image = mockData.image.replace(getColorFromUrl(mockData.image), color);
          image = isInfographicView ? image.replace('seller-guide', 'seller-infographic') : image;
          return (
            <button
              onClick={() => setSelectedColor(color)}
              key={color}
              className="cursor-pointer"
            >
              <div className='w-7 h-7 rounded-full'>
                <div
                  className="w-[26px] h-[26px] border border-neutral-200 rounded-full flex items-center justify-center"
                  style={{ backgroundColor: colors[color] }}
                >
                  {color === selectedColor && <CheckIconGuides />}
                </div>
              </div>
            </button>
          );
        })}
      </>
    );
  };

  return (
    <div className="fixed inset-0 bg-neutral-50">
      {isPublishing && (
        <div className="w-full h-dvh flex items-center justify-center bg-neutral-700 bg-opacity-50 fixed inset-0 z-20">
          <div className="flex flex-col gap-2 items-center justify-center bg-white w-80 h-52 rounded-xl shadow-box py-8">
            <Spinner />
            <span className="text-neutral-800 text-base font-bold mt-6">
              Publishing your guide...
            </span>
            <span className="text-neutral-500 text-sm font-medium">
              Please wait while we publish your guide.
            </span>
          </div>
        </div>
      )}

      <div className="w-full self-stretch h-16 flex-col justify-start items-center inline-flex">
        <div className="self-stretch h-16 px-6 pt-2 pb-[9px] bg-neutral-800 justify-between items-center inline-flex z-10 max-sm:px-3">
          <button
            className="justify-start items-center gap-2 flex cursor-pointer max-sm:gap-0.5"
            onClick={handleGoBack}
            aria-label="Back"
          >
            <ArrowLeft />
            <div className="text-neutral-50 text-sm font-bold leading-tight">
              Back
            </div>
          </button>
          <div className="justify-center items-center gap-1 flex">
            <button
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${!isInfographicView ? 'bg-neutral-700' : ''
                }`}
              onClick={() => handleViewSwitch('guide')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('guide');
              }}
              aria-label="Guide View"
            >
              <GuideIconFilled className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight max-sm:hidden">
                Guide
              </div>
            </button>
            <button
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${isInfographicView ? 'bg-neutral-700' : ''
                }`}
              onClick={() => handleViewSwitch('infographic')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('infographic');
              }}
              aria-label="Infographic View"
            >
              <InfographicIconFilled className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight 
                  max-sm:hidden">
                Infographic
              </div>
            </button>
          </div>
          <div className="justify-start items-center gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              <button
                className="h-[46px] px-[18px] py-[13px] bg-sky-400 rounded-md justify-center items-center flex cursor-pointer"
                onClick={handlePublish}
                aria-label="Publish"
              >
                <div className="text-neutral-800 text-sm font-bold leading-tight">
                  Publish
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex" style={{ height: 'calc(100vh - 64px)' }}>
        <div className="flex bg-white">
          <div className="w-[84px] border-r border-neutral-200 pl-2 pt-3.5">
            <div className="flex">
              <div className="flex gap-1">
                <button
                  onClick={() => setHideOptions(false)}
                  className="bg-neutral-100 rounded-md w-[68px] h-[68px] flex flex-col justify-center items-center gap-1"
                >
                  <PaintPaletteIcon />
                  <p className="text-xs font-bold">Colors</p>
                </button>
                <div className="
                  h-full
                  flex
                  relative
                  items-center
                  after:w-1
                  after:h-[54px]
                  after:content-[' ']
                  after:absolute
                  after:right-[-4px]
                  after:top-1
                  after:h-8
                  after:bg-blueBorder
                  after:transition-all
                  after:duration-200
                  after:rounded-l-md
                  hover:after:w-2
                " />
              </div>
            </div>
          </div>
          {!hideOptions && (
            <div className="w-[328px] max-sm:w-[300px] bg-white z-10">
              <div className="flex justify-between items-center border-b border-neutral-200 px-4 py-3.5">
                <p className="font-semibold">Available colors</p>
                <button
                  className="p-2.5 bg-neutral-100 rounded-md cursor-pointer hover:bg-neutral-200"
                  onClick={() => setHideOptions(true)}
                >
                  <CloseCircleIcon />
                </button>
              </div>
              <div
                className="px-3.5 py-6 overflow-y-auto"
                style={{ maxHeight: 'calc(100vh - 125px)' }}
              >
                <div className="flex gap-2 mb-[34px] py-2.5 px-3 bg-neutral-100 rounded-md">
                  <div className="w-5 mt-0.5">
                    <InfoIcon fill="#000000" className="w-5" />
                  </div>
                  <p>The color will be applied to both your guides and the infographic.</p>
                </div>
                <div className="flex flex-wrap items-center justify-center gap-2">
                  {cards()}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-full flex items-center justify-center bg-neutral-700 p-4 overflow-y-auto  max-sm:items-start">
          <img
            src={displayedImage}
            alt={mockData.title}
            className="h-auto max-w-[592px] object-contain my-auto"
            style={{ maxHeight: 'calc(100vh - 112px)' }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditGuide;
