import { DashboardTemplate } from 'modules/v2/templates';
import GuideCheckout from './GuideCheckout/index';

const Checkout = () => {

  return (
    <DashboardTemplate hasSidebar >
      <GuideCheckout />
    </DashboardTemplate>
  );
};

export default Checkout;
