import { combineReducers } from 'redux';

import addresses from './addresses';
import books from './books';
import categories from './categories';
import countries from './countries';
import customerPortalLink from './customerPortalLink';
import drafts from './drafts';
import intakeForm from './intakeForm';
import orders from './orders';
import profile from './profile';
import releaseNotes from './releaseNotes';
import states from './states';
import support from './support';
import user from './user';
import offers from './offers';
import globalProfile from './globalProfile';

const reducer = combineReducers({
  addresses,
  books,
  categories,
  countries,
  customerPortalLink,
  drafts,
  intakeForm,
  offers,
  orders,
  profile,
  releaseNotes,
  states,
  support,
  user,
  globalProfile,
});

export default reducer;
