import { UPDATE_ISBOOKS, UPDATE_ISRM, UPDATE_ISGUIDES } from '../actions/bundleAccess';

const initialState = {
  isBooks: false,
  isRm: false,
  isGuides: false,
};

export default function bundleAccessReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ISBOOKS:
      return {
        ...state,
        isBooks: action.payload,
      };
    case UPDATE_ISRM:
      return {
        ...state,
        isRm: action.payload,
      };
    case UPDATE_ISGUIDES:
      return {
        ...state,
        isGuides: action.payload,
      };

    default:
      return state;
  }
}
