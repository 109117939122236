import { ChangeEventHandler } from 'react';
import { TextInput as BaseInput } from 'flowbite-react';
import classnames from 'classnames';
import { defaultTextInputTheme } from '../../atoms/TextInput/theme';

type QuantitySelectorProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: number;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  errorMessage?: string;
  sizing?: 'sm' | 'md' | 'lg';
  color?: 'gray' | 'failure';
  isValid?: boolean;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  maximum?: number;
  minimum?: number;
  step?: number;
};

const QuantitySelector = ({
  onChange,
  value,
  sizing = 'lg',
  errorMessage = 'Invalid input',
  disabled,
  className,
  containerClassName,
  color = 'gray',
  type = 'text',
  isValid,
  maximum,
  minimum = 8,
  step = 4,
  ...props
}: QuantitySelectorProps) => {
  const handleDecrement = () => {
    if (value - step < minimum) {
      onChange({ target: { value: minimum } });
    } else {
      onChange({ target: { value: Number(value - step) } });
    }
  };

  const handleIncrement = () => {
    if (value + step > maximum) {
      onChange({ target: { value: maximum } });
    } else {
      onChange({ target: { value: Number(value + step) } });
    }
  };

  const handleInputChange = (event) => {
    onChange({ target: { value: Number(event.target.value) } });
  };

  return (
    <div className={`flex flex-col gap-3.5 ${containerClassName}`}>
      <div className="flex justify-center items-center">
        <button
          type="button"
          onClick={handleDecrement}
          disabled={disabled}
          className={`w-11 rounded-lg text-lg font-bold p-2 ${
            disabled
              ? 'cursor-not-allowed bg-neutral-200 text-neutral-400 opacity-50'
              : 'bg-neutral-100 text-neutral-600 hover:bg-neutral-200 cursor-pointer'
          }`}
        >
          -
        </button>
        <BaseInput
          onChange={handleInputChange}
          value={value}
          theme={defaultTextInputTheme}
          disabled={disabled}
          type={type}
          color={isValid ? color : 'failure'}
          sizing={sizing}
          style={{ textAlign: 'center' }}
          className={classnames(className, 'ml-2 mr-2 w-24')}
          defaultValue={minimum}
          {...props}
        />
        <button
          type="button"
          disabled={disabled}
          onClick={handleIncrement}
          className={`w-11 rounded-lg text-lg font-bold p-2 ${
            disabled
              ? 'cursor-not-allowed bg-neutral-200 text-neutral-400 opacity-50'
              : 'bg-neutral-100 text-neutral-600 hover:bg-neutral-200 cursor-pointer'
          }`}
        >
          +
        </button>
      </div>
      <span className="font-semibold text-xs text-danger-500">{isValid ? '' : errorMessage}</span>
    </div>
  );
};

export default QuantitySelector;
