import { spawn } from 'redux-saga/effects';

import addressWatchers from './address';
import booksWatchers from './books';
import categoriesWatchers from './categories';
import countriesWatchers from './countries';
import customerPortalLinkWatchers from './customerPortalLink';
import draftsWatchers from './drafts';
import intakeFormWatchers from './intakeForm';
import ordersWatchers from './orders';
import profileWatchers from './profile';
import releaseNotesWatchers from './releaseNotes';
import statesWatchers from './states';
import supportWatchers from './support';
import userWatchers from './user';
import globalProfileWatchers from './globalProfile';

export default function* saga() {
  yield spawn(addressWatchers);
  yield spawn(booksWatchers);
  yield spawn(categoriesWatchers);
  yield spawn(countriesWatchers);
  yield spawn(customerPortalLinkWatchers);
  yield spawn(draftsWatchers);
  yield spawn(intakeFormWatchers);
  yield spawn(ordersWatchers);
  yield spawn(profileWatchers);
  yield spawn(releaseNotesWatchers);
  yield spawn(statesWatchers);
  yield spawn(supportWatchers);
  yield spawn(userWatchers);
  yield spawn(globalProfileWatchers);
}
