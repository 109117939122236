import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';

import { GET_GLOBAL_PROFILE, UPDATE_GLOBAL_PROFILE } from 'modules/dashboard/store/constants';
import { globalProfile as initialState } from '../initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_GLOBAL_PROFILE}_REQUESTED`:
    case `${UPDATE_GLOBAL_PROFILE}_REQUESTED`: {
      return { ...state, status: STATUS_LOADING };
    }

    case `${UPDATE_GLOBAL_PROFILE}_SUCCEEDED`:
    case `${GET_GLOBAL_PROFILE}_SUCCEEDED`: {
      const data = action.payload ?? null;

      return {
        ...state,
        ...data,
        status: STATUS_SUCCESS,
      };
    }

    case `${GET_GLOBAL_PROFILE}_FAILED`:
    case `${UPDATE_GLOBAL_PROFILE}_FAILED`: {
      return { ...state, status: STATUS_ERROR };
    }

    default:
      return state;
  }
};

export default reducer;
