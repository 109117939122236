export const WELCOME_JOURNEY = 'welcome-journey';
export const REFERRAL_MARKETING = 'referral-marketing';
export const MY_BOOKS = 'my-books';
export const CAMPAIGNS = 'Campaigns';
export const CAMPAIGNS_KEY = 'email-campaign';
export const LEADS = 'Leads';
export const LEADS_KEY = 'leads';
export const TRAINING = 'Training';
export const GUIDES_AUTOMATION = 'my-guides';
export const MY_BOOKS_GUIDES = 'my-books-guides';
export const REFERRAL_MARKETING_GUIDES = 'referral-marketing-guides';
