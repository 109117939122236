import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { get } from 'lodash-es';

import LogoImg from 'assets/images/logo/authorify-logo-black.svg';
import { getRouteHomePage } from 'modules/dashboard/routes/navigation';
import {
  DatabaseIcon,
  MobileMenuIcon,
  QuestionCircleOutlined,
} from 'modules/v2/common/components/SvgIcon';
import { Button as AtomicButton } from 'modules/v2/common/AtomicDesign/atoms';
import { fetchUserCredits } from 'modules/api';

import { useSelector } from 'react-redux';
import { Button, ButtonGroup, Dropdown, Title, UserMenu, Menu, SitesDropdown } from './components';
import S from './styles';

const NavBar = ({
  mainContent,
  sideContent,
  fixed,
  hasSeparator,
  mobileDevice,
  setShowHelpModal,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);
  const bookCreditsLeft = get(userBookCredit, 'data.data.credits', 0);
  const { isGuides } = useSelector((data) => data.bundleAccess);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <S.Wrapper fixed={fixed}>
        <S.Wrapper.Main>
          {windowSize > 1024 ? null : (
            <div className="cursor-pointer" onClick={mobileDevice}>
              {' '}
              <MobileMenuIcon />
            </div>
          )}
          <Link className="" to={getRouteHomePage}>
            <img width="40px" height="40px" src={LogoImg} alt="Authorify Logo" />
          </Link>
          <S.Separator showLine={hasSeparator} />
          {mainContent}
        </S.Wrapper.Main>
        <S.Wrapper.Side>
          {!isGuides && (
            <div className="gap-2 border text-neutral-800 h-[38px] font-semibold rounded-md pl-[12px] pt-[10px] pr-[14px] pb-[10px] text-xs inline-flex min-w-[104px]">
              <DatabaseIcon />
              <p className="text-nowrap">{bookCreditsLeft} credits</p>
            </div>
          )}
          <AtomicButton
            size="sm"
            onClick={() => setShowHelpModal(true)}
            type="muted"
            className="w-[38px] h-[38px]"
          >
            <QuestionCircleOutlined />
          </AtomicButton>
          {sideContent}
        </S.Wrapper.Side>
      </S.Wrapper>
    </>
  );
};

NavBar.Button = Button;
NavBar.ButtonGroup = ButtonGroup;
NavBar.Dropdown = Dropdown;
NavBar.Title = Title;
NavBar.UserMenu = UserMenu;
NavBar.Menu = Menu;
NavBar.SitesDropdown = SitesDropdown;

export default NavBar;
