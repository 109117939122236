import { useState } from 'react';

import { DashboardTemplate } from 'modules/v2/templates';
import { SubMenu } from 'modules/v2/common/AtomicDesign/molecules';
import { GuidesProvider } from './context';
import OrderedGuides from './OrderedGuides/index';

const MyOrder = () => {
  const [subMenuItems, setSubMenuItems] = useState([
    { title: 'Guides', id: 'guides', active: true },
  ]);

  const changeActiveItem = (id) => {
    const newItems = subMenuItems.map((item) => {
      if (item.id === id) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setSubMenuItems(newItems);
  };

  const subHeader = <SubMenu changeActiveItem={changeActiveItem} items={subMenuItems} />;

  const showCurrentComponent = (id) => {
    switch (id) {
      case 'guides':
        return <OrderedGuides changeActiveItem={changeActiveItem} items={subMenuItems} />;
      default:
        return <OrderedGuides changeActiveItem={changeActiveItem} items={subMenuItems} />;
    }
  };

  return (
    <DashboardTemplate hasSidebar title="Order History" subHeader={subHeader}>
      <GuidesProvider>
        {showCurrentComponent(subMenuItems.find((item) => item.active).id)}
      </GuidesProvider>
    </DashboardTemplate>
  );
};

export default MyOrder;
