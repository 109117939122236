import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ArrowUpIcon, LightningBoltIcon } from 'modules/v2/common/components/SvgIcon';
import MenuItem from './MenuItem';
import { useSelector } from 'react-redux';

type DropdownMenuProps = {
  open: boolean;
  options: [];
  item: any;
  current: boolean;
  welcomeJourneyEligible?: boolean;
};

const DropdownMenu = ({
  item,
  open = false,
  options,
  current,
  welcomeJourneyEligible = false,
}: DropdownMenuProps) => {
  const { pathname } = window.location;
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(open);

  const isDisabled = welcomeJourneyEligible ? item.name !== 'Welcome journey' : false;

  const handleItemClick = () => {
    if (item.href) {
      setIsOpen(true);
      history.push(item.href);
    } else {
      setIsOpen(!isOpen);
    }
  };

  if (isDisabled) {
    return (
      <span
        className={classNames(
          `
        m-0
        w-full
        h-[39px]
        flex
        relative
        items-center
        cursor-not-allowed
        opacity-50
        pr-3
      `,
        )}
      >
        <div className="w-full mr-2 flex gap-[10px] items-center px-2 py-[9.5px] rounded-lg">
          <item.icon active={isOpen} />
          <span>{item.name}</span>
        </div>
        <div
          className={classNames(
            isOpen ? 'text-neutral-800' : 'text-neutral-700 rotate-180',
            'h-5 w-5 shrink-0 mx-2',
          )}
        >
          <ArrowUpIcon />
        </div>
      </span>
    );
  }
  return (
    <>
      <div className="pr-3">
        <button
          type="button"
          onClick={handleItemClick}
          className={classNames(
            `
            flex
            items-center
            w-full
            text-left
            rounded-md
            gap-x-3
            font-medium
            hover:bg-gray-100
            text-neutral-600
          `,
          )}
        >
          <div className="w-full mr-2 flex gap-[10px] items-center px-2 py-[9.5px] rounded-lg">
            <item.icon active={isOpen} />
            <span>{item.name}</span>
          </div>
          <div
            className={classNames(
              isOpen ? 'text-neutral-800' : 'text-neutral-700 rotate-180',
              'h-5 w-5 shrink-0 mx-2',
            )}
          >
            <ArrowUpIcon />
          </div>
        </button>
      </div>

      <ul className={classNames(isOpen ? 'block space-y-2 py-2' : 'hidden')}>
        {item.children.map((subItem) => (
          <li key={subItem.key}>
            <MenuItem
              name={subItem?.name}
              Icon={subItem?.icon}
              href={subItem?.href}
              isChild
              current={pathname === subItem?.href}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default DropdownMenu;
