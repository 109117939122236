import { useHistory } from 'react-router-dom';
import { CheckIconGuides } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { getMyCheckout, getRouteGuidePreview } from 'modules/v2/routes/navigation';

export const GuidePreview = ({ guideImage }) => {
  const history = useHistory();

  const handleCheckout = () => {
    history.push(getMyCheckout());
  };

  const handlePreviewClick = () => {
    history.push(getRouteGuidePreview());
  };

  return (
    <div>
      <div className="relative group w-[252px] h-[332px] rounded-lg">
        <div className='bg-grey-100 relative w-[252px] h-[332px] rounded-lg border neutral-300 overflow-hidden cursor-pointer transition-transform duration-300 ease-in-out' >
          <img src={guideImage} alt="guide-cover" className="w-full h-full object-cover"/>
        </div>

        <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <Button type='outlined' className='mb-2 px-14' onClick={handlePreviewClick}>Preview</Button>
          <Button type='primary' className='px-12' onClick={handleCheckout}>Buy Again</Button>
        </div>
      </div>

      <div className='py-2 flex gap-2 flex-row'>
        <div className='h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-opt1' >
          <CheckIconGuides />
        </div>
        <div className='h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-opt2' />
        <div className='h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-opt3' />
        <div className='h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-opt4' />
        <div className='h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-opt5' />
        <div className='h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-opt6' />
      </div>
      <div className='text-sm font-medium'>
        <p className='truncate'>A Guide to Selling Your Home</p>
        <span className='text-neutral-500'>Seller</span>
      </div>
    </div>
  );
};